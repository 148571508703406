import seedrandom from "seedrandom";
import axios from "axios";
import { SEND_NOTIFICATION, GET_USER_GROUPS } from "../api-calls/api-urls";
import {
  api,
  authApi,
  getAuthorizationHeader,
} from "../api-calls/user-requests";
import { setSortName } from "../Redux/actions/user-actions";
import moment from "moment";
import { SaveGroupsList, SaveMessages } from "../Redux/actions/chatActions";
import mqtt from "mqtt";
import store from "../Redux/store";

const MqttBrokerUrl = async () => {
  // const urlData = await JSON.parse(sessionStorage.getItem("MqttUrlData")); 

  // if (urlData && moment().isBefore(urlData.expireTime)) {
  //   return urlData.signedUrl;
  // } else {
    const { data } = await authApi.get(`/map/get-aws-token`, {
      headers: { Authorization: getAuthorizationHeader() },
    });
    // sessionStorage.setItem("MqttUrlData", JSON.stringify(data));
    return data.signedUrl;
  // }
};

export const Alphabetic = (event) => {
  var x = event.charCode || event.keyCode;
  if ((x > 96 && x < 123) || (x > 64 && x < 91) || x === 32) {
    return true;
  } else {
    event.preventDefault();
    return false;
  }
};

export const Numeric = (event) => {
  var x = event.charCode || event.keyCode;
  if (x > 47 && x < 58) {
    return true;
  } else {
    event.preventDefault();
    return false;
  }
};

export const checkOnline = (id) => {
  // console.log("checkOnline ID", id);
  const { teamMembersList } = store.getState()?.reducers?.allUsers;

  if (teamMembersList && teamMembersList.length > 0) {
    const teamMember = teamMembersList.find(
      (member) => member?.user_id?._id === id
    );
    // console.log("teamMember", teamMember);
    return teamMember?.status;
  }
  return null;
};

let client = null;
export const ConnectMqtt = () => {};

export const getMqttData = async (id, userList, dispatch, from) => {
  const clientId = `teamplayer-${Math.random().toString(16).substr(2, 8)}`;
  // let topic;
  if (from === "map") {
    const { data } = await api.post(`${SEND_NOTIFICATION}/${id}`);
    // topic = data.topic;
    localStorage.setItem("mqttTopic", data.topic);
  }
  // console.log(data);
  if (
    !client?.connected ||
    !client ||
    client === undefined ||
    client === null
  ) {
    const MqttURL = await MqttBrokerUrl();
    console.log(MqttURL);

    client = new mqtt.connect(MqttURL, {
      clientId: clientId,
      clean: false,
      reconnectPeriod: 10000,
      protocolVersion: 5,
    });

    client.on("connect", () => {
      console.log("Connected to MQTT broker");
      try {
        userList.forEach(async (playerId) => {
          console.log(`App/${id}/${playerId.user_id._id}/Status`);          
          client.subscribe(`App/${id}/${playerId.user_id._id}/Status`);
        });
      } catch (err) {
        console.log(err);
      }
    });

    client.on("message", (topic, message) => {
      try {
        const regex = /^App\/[a-z0-9]+\/[a-z0-9]+\/Status$/; // for status updates
        const regexMapData = /^App\/[a-z0-9]+$/;
        if (regex.test(topic)) {
          changeStatus(JSON.parse(message.toString()), dispatch);
        } else {
          if (regexMapData.test(topic)) {
            changePosition(JSON.parse(message.toString()), dispatch);
          }
        }
        // changeFunction(JSON.parse(message.toString()));
      } catch (err) {
        console.log(err, message.toString());
      }
    });

    client.on("error", (err) => {
      console.error("MQTT Client Error:", err);
    });

    client.on("reconnect", () => {
      console.log("Reconnecting to MQTT broker");
    });

    return () => {
      client.end(); // Disconnect from the MQTT broker when the component unmounts
    };
  }

  const topic = localStorage.getItem("mqttTopic");
  client.subscribe(topic);
  userList.forEach(async (playerId) => {
    if (from === "map") {
      client.publish(
        `App/${playerId?.department?.company_id}/${playerId.user_id._id}/Livedata`,
        JSON.stringify({ topic: `${topic}` }),
        { qos: 0, retain: false }
      );
    }
  });
};

const changePosition = async (data, dispatch) => {
  try {
    const { teamMembersList } = store.getState()?.reducers?.allUsers;

    const updatedTeamMembersList = teamMembersList.map((Player) => {
      if (Player?.user_id?._id === data?.user_id) {
        return {
          ...Player,
          position: {
            lat: parseFloat(data?.lat),
            lng: parseFloat(data?.lng),
          },
          status: data?.status || "checkout",
          batteryLevel: data?.batteryLevel,
        };
      }
      return Player;
    });
    dispatch({ type: "TEAM_MEMBERS_LIST", payload: updatedTeamMembersList });
  } catch (err) {
    console.log(err);
  }
};

const changeStatus = async (data, dispatch) => {
  const { teamMembersList } = store.getState()?.reducers?.allUsers;

  const updatedTeamMembersList = teamMembersList.map((player) => {
    if (player?.user_id?._id === data?.user_id) {
      return {
        ...player,
        status: data?.status,
        position: data?.location,
        batteryLevel: data?.batteryLevel,
        ...(data?.device_id && { device_id: data?.device_id }),
      };
    }
    return player;
  });
  dispatch({ type: "TEAM_MEMBERS_LIST", payload: updatedTeamMembersList });
};

export const PublishMessage = async (topic) => {
  const clientId = `teamplayer-${Math.random().toString(16).substr(2, 8)}`;
  if (!client?.connected || !client || client === undefined) {
    const MqttURL = await MqttBrokerUrl();
    console.log(MqttURL);
    client = new mqtt.connect(MqttURL, {
      clientId: clientId,
      reconnectPeriod: 10000,
    });
  }
  console.log("Publish Message", topic);
  client.on("connect", () => {
    console.log("Connected to MQTT broker");
    client.publish(topic, JSON.stringify({ forcelogout: 1 }), {
      qos: 0,
      retain: true,
    });
  });
};

export const getMapCenter = (setMapcenter, markerList) => {
  if (!markerList || markerList.length === 0) {
    console.log("Marker list is empty or undefined.");
    return;
  }

  const totalMarkers = markerList.length;
  let totalLat = 0;
  let totalLng = 0;

  markerList.forEach((marker) => {
    console.log(marker?.position?.lat, marker?.position?.lng);
    totalLat += marker?.position?.lat;
    totalLng += marker?.position?.lng;
  });

  const centerLat = totalLat / totalMarkers;
  const centerLng = totalLng / totalMarkers;

  setMapcenter({
    lat: centerLat,
    lng: centerLng,
  });

  console.log({
    lat: centerLat,
    lng: centerLng,
  });
};

export const splitFirstLetter = (name) => {
  let new_name = name
    ?.split(" ")
    .map((val) => val?.at(0)?.toUpperCase())
    .join("");
  return new_name;
};

export const fetchLocation = (code, setLocation) => {
  const mapkey = "AIzaSyDaVncl1j0jHuHY50J0VXcMHl19Vr8p-HU";
  const url = `https://maps.googleapis.com/maps/api/geocode/json?address=${code}&region=in&key=${mapkey}`;
  axios
    .get(url, {
      headers: {
        "Content-Type": "multipart/form-data",
        Accept: "application/json",
      },
    })
    .then((res) => {
      if (res.data.results[0]) {
        const addressComponents = res.data.results[0].address_components;
        let city = addressComponents[1].long_name;
        let state = addressComponents[2].long_name;
        const loca = `${city}, ${state}`;
        setLocation(loca);
      }
    })
    .catch((err) => {
      console.log("err", err);
    });
};

export const generateRandomString = (inputText) => {
  const alphabet =
    "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
  const rng = seedrandom(inputText);
  // Create a seeded random number generator

  let randomString = "";

  for (let i = 0; i < inputText.length + 20; i++) {
    const randomIndex = Math.floor(rng() * alphabet.length);
    randomString += alphabet[randomIndex];
  }

  return randomString;
};

export const generateRandomStringforMap = () => {
  const alphanumericChars = "abcdefghijklmnopqrstuvwxyz0123456789";
  let randomString = "";
  const length = 10;

  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * alphanumericChars.length);
    randomString += alphanumericChars.charAt(randomIndex);
  }

  return randomString;
};

export function formatBytes(bytes, decimals = 2) {
  if (!+bytes) return "0 Bytes";

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
}

export const generateColor = (text) => {
  const randomst = generateRandomString(text);
  const cancat = text.replace(" ", "") + randomst;
  const hashCode = (s) => {
    let h = 0;
    for (let i = 0; i < s.length; i++) {
      h = (Math.imul(31, h) + s.charCodeAt(i)) | 0;
    }
    return h;
  };

  const hash = hashCode(cancat);

  const redComponent = (hash & 0xff0000) >> 16;
  const greenComponent = (hash & 0x00ff00) >> 16;
  const blueComponent = hash & 0x0000ff;

  const color1 = `#${redComponent.toString(16).padStart(2, "0")}${greenComponent
    .toString(16)
    .padStart(2, "0")}${blueComponent.toString(16).padStart(2, "0")}`;

  // If you want to generate a second color with reduced opacity (e.g., 40%), you can do this:
  const color2 = `${color1}66`; // 66 represents the alpha value (opacity) in hexadecimal

  return color1;
};

export const getTime = (date) => {
  const currentDate = new Date();
  const inputDate = new Date(date);
  const yesterday = new Date(currentDate);
  yesterday.setDate(currentDate.getDate() - 1);

  // Check if it's today
  if (
    inputDate.getDate() === currentDate.getDate() &&
    inputDate.getMonth() === currentDate.getMonth() &&
    inputDate.getFullYear() === currentDate.getFullYear()
  ) {
    return "today";
  } else if (
    inputDate.getDate() === yesterday.getDate() &&
    inputDate.getMonth() === yesterday.getMonth() &&
    inputDate.getFullYear() === yesterday.getFullYear()
  ) {
    return "yesterday";
  } else {
    const newDate = moment(date).format("DD/MM/YYYY");
    return newDate;
  }
};

// export const stringToColor = (string) => {
//   let hash = 0;
//   let i;

//   /* eslint-disable no-bitwise */
//   for (i = 0; i < string.length; i += 1) {
//     hash = string.charCodeAt(i) + ((hash << 5) - hash);
//   }

//   let color = "#";

//   for (i = 0; i < 3; i += 1) {
//     const value = (hash >> (i * 8)) & 0xff;
//     color += `00${value.toString(16)}`.slice(-2);
//   }
//   /* eslint-enable no-bitwise */

//   return color;
// };

export const stringAvatar = (name) => {
  if (name) {
    if (
      name.trimStart().trimEnd().includes(" ") &&
      name.split(" ")[1].length > 1
    ) {
      return {
        children: `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`,
      };
    } else {
      return {
        children: `${name[0]}${name[1] ? name[1] : ""}`,
      };
    }
  } else {
    return {
      children: "",
    };
  }
};

export const getNotificationUnseenCount = (notificationList) => {
  if (notificationList && notificationList.length > 0) {
    return notificationList.filter((item) => item.is_seen === false).length;
  }
  return 0;
};

export const getInitialName = (name) => {
  if (name) {
    if (name.includes(" ")) {
      return `${name.split(" ")[0][0]} ${name.split(" ")[1][0]}`;
    } else {
      return `${name[0]}${name[1]}`;
    }
  }
};
//get a languagejson from backend and need to change the lable

export const getChipColor = (name) => {
  const style = {
    backgrounndColor: "",
    color: "",
    padding: "",
    fontSize: "13px",
    textTransform: "capitalize",
  };
  if (name === "pending") {
    style.backgroundColor = "#F8F3E7";
    style.color = "#836824";
    style.padding = "13px 3px";

    return style;
  }
  if (name === "completed") {
    style.backgroundColor = "#E6F9F0";
    style.color = "#1A6B44";
    style.padding = "13px 3px";
    return style;
  }
  if (name === "rejected") {
    style.backgroundColor = "#FEE1E1";
    style.color = "#9E0303";
    style.padding = "13px 3px";
    return style;
  }
};

export function filterLocationsWithinDistance(locations, maxDistance) {
  if (locations.length < 2) {
    return locations; // If there are fewer than 2 locations, nothing to filter
  }

  const filteredLocations = [locations[0]]; // Start with the first location

  for (let i = 1; i < locations.length; i++) {
    const currentLocation = locations[i];
    const previousLocation = filteredLocations[filteredLocations.length - 1];

    const distance = calculateDistance(
      currentLocation.lat,
      currentLocation.lng,
      previousLocation.lat,
      previousLocation.lng
    );

    if (distance >= maxDistance) {
      filteredLocations.push(currentLocation);
    }
  }

  return filteredLocations;
}

export const handleSortClick = (dispatch, sortBy, name) => {
  const namearr = name?.split("-");
  const sortByarr = sortBy?.split("-");
  if (sortByarr && sortByarr.length > 0 && namearr[0] === sortByarr[0]) {
    if (sortByarr[1] === "asc") {
      dispatch(setSortName(`${namearr[0]}-desc`));
    } else {
      dispatch(setSortName(`${namearr[0]}-asc`));
    }
  } else {
    dispatch(setSortName(`${namearr[0]}-asc`));
  }
};

// Helper function to calculate the distance between two locations using the Haversine formula
export function calculateDistance(lat1, lng1, lat2, lng2) {
  const earthRadius = 6371000; // Earth's radius in meters
  const lat1Rad = (lat1 * Math.PI) / 180;
  const lat2Rad = (lat2 * Math.PI) / 180;
  const dLat = lat2Rad - lat1Rad;
  const dLng = ((lng2 - lng1) * Math.PI) / 180;

  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(lat1Rad) *
      Math.cos(lat2Rad) *
      Math.sin(dLng / 2) *
      Math.sin(dLng / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const distance = earthRadius * c;

  return distance;
}

export const getUserGroupList = () => async (dispatch) => {
  try {
    const { data } = await authApi.get(`${GET_USER_GROUPS}`, {
      headers: { Authorization: getAuthorizationHeader() },
    });
    dispatch(SaveGroupsList(data));
  } catch (error) {
    console.log(error);
  }
};

export const getConversationMessages = (member) => async (dispatch) => {
  try {
    const { data } = await authApi.get(
      `/messages/conversations/${member?._id}`,
      {
        headers: { Authorization: getAuthorizationHeader() },
      }
    );
    dispatch(SaveMessages(data));
  } catch (error) {
    console.log(error);
  }
};
