import React, { useEffect, useState } from "react";
import {
  GoogleMap,
  useLoadScript,
  Marker,
  InfoWindow,
  Polyline,
  DirectionsService,
  DirectionsRenderer,
} from "@react-google-maps/api";
import {
  BrudCrumbBackIcon,
  BrudCrumbNextIcon,
} from "../../../components/Icons";
import { useNavigate } from "react-router-dom";
import { LOCATION } from "../../../api-calls/api-urls";
import {
  authApi,
  getAuthorizationHeader,
} from "../../../api-calls/user-requests";
import {
  generateColor,
  getInitialName,
  getMapCenter,
} from "../../../utilities/commonFuntions";

function Map() {
  const navigate = useNavigate();
  const [currentLocation, setCurrentLocation] = useState();
  const userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
  const [activeMarker, setActiveMarker] = useState(null);
  const [mapcenter, setMapcenter] = useState();
  const [directions, setDirections] = useState(null);
  const [totalDistance, setTotalDistance] = useState(0);
  const [position, setPosition] = useState({
    lat: 11.0176052,
    lng: 76.9571077,
  });

  useEffect(() => {
    if (currentLocation && currentLocation.location_info) {
      setMapcenter({
        lat: parseFloat(currentLocation?.location_info?.lat),
        lng: parseFloat(currentLocation?.location_info?.lng),
      });
    }
  }, [currentLocation]);

  useEffect(() => {
    getRouteDirections();
  }, [position, currentLocation]);

  const getMarkerPosition = (value) => {
    console.log(value);
  };

  const getRouteDirections = async () => {
    try {
      const response = await new Promise((resolve, reject) => {
        const directionsService = new window.google.maps.DirectionsService();
        directionsService.route(
          {
            origin: {
              lat: parseFloat(position?.lat),
              lng: parseFloat(position?.lng), // Corrected
            },
            destination: {
              lat: parseFloat(currentLocation.location_info.lat),
              lng: parseFloat(currentLocation.location_info.lng), // Corrected
            },
            travelMode: "DRIVING",
          },
          (result, status) => {
            if (status === window.google.maps.DirectionsStatus.OK) {
              resolve(result);
            } else {
              reject(status);
            }
          }
        );
      });
      console.log(response);

      let dis = 0;

      if (response && response.routes.length > 0) {
        response.routes[0].legs.map((item) => {
          dis += parseFloat(item.distance.text);
        });

        console.log(dis.toFixed(2));
        setTotalDistance(dis.toFixed(2));
      }
      setDirections(response);
    } catch (error) {
      console.log("Error fetching directions:", error);
    }
  };

  const getMarkerSVG = (name) => {
    console.log("name", name);
    const isSelectedMarker = activeMarker && activeMarker === name;
    const fillColor = isSelectedMarker ? "white" : generateColor(name);
    const strokeColor = generateColor(name);

    return `<svg width="36px" height="36px" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="18.138" cy="8.4625" r="4.8801" fill="${fillColor}" stroke="${strokeColor}" stroke-width="2"/>
        <rect x="9.0039" y="15.0443" width="18.1395" height="17.6213" rx="6.2222" fill="${fillColor}" stroke="${strokeColor}" stroke-width="2"/>
    
        <text x="50%" y="67%" dominant-baseline="middle" text-anchor="middle" fill="${
          isSelectedMarker ? "black" : "white"
        }" font-size="6px" font-family="Arial">
          ${getInitialName(name).toUpperCase()}
        </text>
      </svg>`;
  };

  const getMarkerSVGLocation = () => {
    if (currentLocation.status === "pending") {
      const isSelectedMarker =
        activeMarker && activeMarker === currentLocation.title;

      if (isSelectedMarker) {
        return `<svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="0.25" y="0.25" width="47.5" height="47.5" rx="3.75" fill="#D09D1C" stroke="#D09D1C" stroke-width="0.5"/>
      <path d="M8.66626 15.3345L8.66686 15.3339C9.22604 14.7738 9.88349 14.5007 10.6663 14.5007H17.333H17.833V14.0007V10.6673C17.833 9.88444 18.1062 9.22627 18.6666 8.66587C19.2257 8.10676 19.8832 7.83398 20.6663 7.83398H27.333C28.1159 7.83398 28.774 8.10663 29.3343 8.66569C29.8936 9.226 30.1663 9.88422 30.1663 10.6673V14.0007V14.5007H30.6663H37.333C38.1159 14.5007 38.774 14.7738 39.3345 15.3342C39.8936 15.8933 40.1663 16.5509 40.1663 17.334V35.6673C40.1663 36.4503 39.8936 37.1085 39.3345 37.6688C38.7742 38.228 38.116 38.5007 37.333 38.5007H10.6663C9.88321 38.5007 9.22567 38.2279 8.66656 37.6688C8.10616 37.1084 7.83301 36.4502 7.83301 35.6673V17.334C7.83301 16.5511 8.10613 15.8937 8.66626 15.3345ZM20.1663 14.0007V14.5007H20.6663H27.333H27.833V14.0007V10.6673V10.1673H27.333H20.6663H20.1663V10.6673V14.0007Z" fill="white" stroke="white"/>
      </svg>
      `;
      } else {
        return `<svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="0.25" y="0.25" width="47.5" height="47.5" rx="3.75" fill="#F0EEE8" stroke="#D09D1C" stroke-width="0.5"/>
        <path d="M8.66626 15.3345L8.66686 15.3339C9.22604 14.7738 9.88349 14.5007 10.6663 14.5007H17.333H17.833V14.0007V10.6673C17.833 9.88444 18.1062 9.22627 18.6666 8.66587C19.2257 8.10676 19.8832 7.83398 20.6663 7.83398H27.333C28.1159 7.83398 28.774 8.10663 29.3343 8.66569C29.8936 9.226 30.1663 9.88422 30.1663 10.6673V14.0007V14.5007H30.6663H37.333C38.1159 14.5007 38.774 14.7738 39.3345 15.3342C39.8936 15.8933 40.1663 16.5509 40.1663 17.334V35.6673C40.1663 36.4503 39.8936 37.1085 39.3345 37.6688C38.7742 38.228 38.116 38.5007 37.333 38.5007H10.6663C9.88321 38.5007 9.22567 38.2279 8.66656 37.6688C8.10616 37.1084 7.83301 36.4502 7.83301 35.6673V17.334C7.83301 16.5511 8.10613 15.8937 8.66626 15.3345ZM20.1663 14.0007V14.5007H20.6663H27.333H27.833V14.0007V10.6673V10.1673H27.333H20.6663H20.1663V10.6673V14.0007Z" fill="#D09D1C" stroke="#D09D1C"/>
        </svg>
        `;
      }
    } else if (currentLocation.status === "completed") {
      // console.log(item);
      const isSelectedMarker =
        activeMarker && activeMarker === currentLocation.title;

      if (isSelectedMarker) {
        return `<svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="0.25" y="0.25" width="47.5" height="47.5" rx="3.75" fill="#1A6B44" stroke="#1A6B44" stroke-width="0.5"/>
      <path d="M8.66626 15.3345L8.66686 15.3339C9.22604 14.7738 9.88349 14.5007 10.6663 14.5007H17.333H17.833V14.0007V10.6673C17.833 9.88444 18.1062 9.22627 18.6666 8.66587C19.2257 8.10676 19.8832 7.83398 20.6663 7.83398H27.333C28.1159 7.83398 28.774 8.10663 29.3343 8.66569C29.8936 9.226 30.1663 9.88422 30.1663 10.6673V14.0007V14.5007H30.6663H37.333C38.1159 14.5007 38.774 14.7738 39.3345 15.3342C39.8936 15.8933 40.1663 16.5509 40.1663 17.334V35.6673C40.1663 36.4503 39.8936 37.1085 39.3345 37.6688C38.7742 38.228 38.116 38.5007 37.333 38.5007H10.6663C9.88321 38.5007 9.22567 38.2279 8.66656 37.6688C8.10616 37.1084 7.83301 36.4502 7.83301 35.6673V17.334C7.83301 16.5511 8.10613 15.8937 8.66626 15.3345ZM20.1663 14.0007V14.5007H20.6663H27.333H27.833V14.0007V10.6673V10.1673H27.333H20.6663H20.1663V10.6673V14.0007Z" fill="white" stroke="white"/>
      </svg>
      `;
      } else {
        return `<svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="0.25" y="0.25" width="47.5" height="47.5" rx="3.75" fill="#F1F6F3" stroke="#1A6B44" stroke-width="0.5"/>
      <path d="M8.66626 15.3345L8.66686 15.3339C9.22604 14.7738 9.88349 14.5007 10.6663 14.5007H17.333H17.833V14.0007V10.6673C17.833 9.88444 18.1062 9.22627 18.6666 8.66587C19.2257 8.10676 19.8832 7.83398 20.6663 7.83398H27.333C28.1159 7.83398 28.774 8.10663 29.3343 8.66569C29.8936 9.226 30.1663 9.88422 30.1663 10.6673V14.0007V14.5007H30.6663H37.333C38.1159 14.5007 38.774 14.7738 39.3345 15.3342C39.8936 15.8933 40.1663 16.5509 40.1663 17.334V35.6673C40.1663 36.4503 39.8936 37.1085 39.3345 37.6688C38.7742 38.228 38.116 38.5007 37.333 38.5007H10.6663C9.88321 38.5007 9.22567 38.2279 8.66656 37.6688C8.10616 37.1084 7.83301 36.4502 7.83301 35.6673V17.334C7.83301 16.5511 8.10613 15.8937 8.66626 15.3345ZM20.1663 14.0007V14.5007H20.6663H27.333H27.833V14.0007V10.6673V10.1673H27.333H20.6663H20.1663V10.6673V14.0007Z" fill="#1A6B44" stroke="#1A6B44"/>
      </svg>`;
      }
    }
  };

  useEffect(() => {
    getOneLocationdata(window.location.pathname);
  }, [window.location.pathname]);

  const getOneLocationdata = async (path) => {
    const arr = path.split("/");
    console.log(arr[arr.length - 1]);
    const { data } = await authApi.get(`${LOCATION}/${arr[arr.length - 1]}`, {
      headers: { Authorization: getAuthorizationHeader() },
    });
    console.log(data.data);
    setCurrentLocation(data.data);
  };

  const handleActiveMarker = (marker) => {
    console.log(marker);
    if (marker.location.title === activeMarker) {
      return;
    }
    setActiveMarker(marker.location.title);
  };

  return (
    <GoogleMap
      center={mapcenter}
      zoom={12}
      mapContainerStyle={{ height: "190px", width: "310px", marginTop: "10px" }}
    >
      {/* {currentLocation &&
                <Marker
                    key={0}
                    position={{
                        lat: parseFloat(position?.lat),
                        lng: parseFloat(position?.lng),
                    }}
                    onClick={() => handleActiveMarker()}
                    icon={{
                        url: `data:image/svg+xml;utf-8,${encodeURIComponent(
                            getMarkerSVG(currentLocation?.employee?.name)
                        )}`,
                        anchor: new window.google.maps.Point(20, 40), // Adjust the anchor values as needed
                        scaledSize: new window.google.maps.Size(50, 50),
                    }}
                />
            } */}
      {currentLocation && (
        <Marker
          key={1}
          onClick={() => handleActiveMarker()}
          position={{
            lat: parseFloat(currentLocation?.location_info?.lat),
            lng: parseFloat(currentLocation?.location_info?.lng),
          }}
          icon={{
            url: `data:image/svg+xml;utf-8,${encodeURIComponent(
              getMarkerSVGLocation(currentLocation.title)
            )}`,
            anchor: new window.google.maps.Point(24, 24),
            scaledSize: new window.google.maps.Size(32, 32),
          }}
        />
      )}
      {/* <DirectionsRenderer
                directions={directions}
                options={{
                    suppressMarkers: true, // Prevents default markers from showing
                    polylineOptions: {
                        strokeColor: "#1C71B3", // Customize the polyline color
                        strokeOpacity: 1,
                        strokeWeight: 3,
                    },
                }}
            /> */}
    </GoogleMap>
  );
}

export default function SmallViewRoutes() {
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: "AIzaSyDaVncl1j0jHuHY50J0VXcMHl19Vr8p-HU", // Add your API key
    libraries: ["geometry"], // Load the geometry library
  });

  if (loadError) {
    return <div>Error loading Google Maps</div>;
  }

  return isLoaded ? <Map /> : <div>Loading Google Maps...</div>;
}
